<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2">
                <h3>Новый пользователь</h3>
            </b-card-header>

            <users-form v-model='user'></users-form>
        </b-card>

        <control-panel>
            <b-button variant="danger" @click="$router.go( -1 )">Отмена</b-button>
            <b-button variant="success" @click="createUser" v-if='isDiff'>Создать</b-button>
        </control-panel>
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';

    import UsersForm from './UsersForm';
    import ControlPanel from '../_common/ControlPanel';
    import ErrorsBagList from '../_common/ErrorsBagList';

    import ErrorsBag from '../../utils/errorsBag';
    import { USERS_ENDPOINT } from '../../utils/endpoints';
    import { ROLE_STOCKMAN, ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR } from '../../utils/Roles';

    const DEFAULT_USER = {
        id: null,
        roles: [],
        email: null,
        storages: [],
        username: null,
        fullname: null,
        password: null,
        is_active: true,
        login_barcode: null,
        followingWorkPositions: [],
        current_work_position: null,
        rolesWithoutStorages: [],
        storagesGroupedByRole: {
            ROLE_STOCKMAN: [],
            ROLE_GROUP_SENIOR: [],
            ROLE_STORAGE_SENIOR: []
        },
        contractor_id: null,
    };

    export default {
        name: "UsersCreate",
        components: { UsersForm, ControlPanel, ErrorsBagList },
        computed: {
            isDiff() {
                return this.user && JSON.stringify( this.user ) !== JSON.stringify( DEFAULT_USER );
            }
        },
        data() {
            return {
                ErrorsBag,
                user: DEFAULT_USER,
            };
        },
        methods: {
            createUser() {
                ErrorsBag.discard();

                let params = cloneDeep( this.user );
                params.is_active = this.user.is_active ? 1 : 0;

                this.$http
                    .post( USERS_ENDPOINT, params )
                    .then((response) => {
                        this.$router.push({ name: 'UsersEdit', params: { id: response.data.data.id } });
                    }).catch((error) => {
                        ErrorsBag.fill( error.body )
                        this.$toast.error( 'Есть ошибки! Ознакомьтесь со списком вверху страницы' );
                        window.scrollTo( { top: 0, behavior: 'smooth' } );
                    });
            }
        },
        mounted() {
            this.user = cloneDeep( DEFAULT_USER );
        }
    }
</script>
